import { graphql } from "gatsby"
import React from "react"
import OrderComplete from "../../../components/pages/Shop/OrderComplete"
import SEO from '../../../components/SEO'
import ShopLayout from "../../../layouts/ShopLayout"

const OrderCompletePage = ({ data }: any) => {
  const {
    strapiShopPage: { ShopPageTiles: tiles },
  } = data
  return (
    <>
      <ShopLayout>
        <SEO noIndex />
        <OrderComplete tiles={tiles} />
      </ShopLayout>
    </>
  )
}

export const query = graphql`
  {
    strapiShopPage {
      id
      ShopPageTiles {
        id
        backgroundImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 1100, quality: 90)
            }
          }
        }
        type
        image {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 550, quality: 90)
            }
          }
        }
        title
        button {
          id
          value
          theme
          targetUrl
        }
        BulleltItems {
          id
          value
        }
      }
    }
  }
`

export default OrderCompletePage
