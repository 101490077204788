import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Button, Heart } from "nzk-react-components"
import React from "react"
import styled from "styled-components"
import getGatsbyImage from "utils/getGatsbyImage"
import Tile, { IShopTile } from "./Tile"

export interface IShopLargeTile extends IShopTile {
  title: string
  image: any
  bulletItems: {
    id: string
    value: string
  }[]
}

const LargeTileContent = styled.div`
  display: flex;
  max-height: 300px;
  height: 100%;
  .tile--image {
    height: 100%;
    min-width: 362px;
    > :first-child {
      height: 100%;
    }
  }
  > :last-child {
    padding: 20px 20px 20px 0;
    min-width: 400px;
  }
  > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
  .tile--title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 0;
  }
  .tile--bulletitems {
  }
  .tile--bulletitem {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    > :first-child {
      margin-right: 10px;
    }
  }
  .tile--button {
    margin-top: auto;
  }
  flex-wrap: wrap;
  @media (max-width: 815px) {
    max-height: none;
    flex-direction: column-reverse;
    align-items: center;
    .tile--image {
      max-height: 300px;
    }
    > :last-child {
      padding: 20px 20px 20px 20px;
    }
  }
`

const LargeTile = (props: IShopLargeTile) => (
  <Tile {...props} width="100%">
    <LargeTileContent>
      <div className="tile--image">
        {props.image && (
          <GatsbyImage
            image={getGatsbyImage(props.image)}
            alt={props.image.alternativeText || ""}
            objectFit="contain"
            objectPosition="50% 50%"
          />
        )}
      </div>
      <div>
        <h2 className="tile--title">{props.title}</h2>
        <div className="tile--bulletitems">
          {props.bulletItems.map(item => (
            <div key={item.id} className="tile--bulletitem">
              <Heart height='25px' width='25px' />
              {item.value}
            </div>
          ))}
        </div>
        <div className="tile--button">
          <Link to={props.button.target}>
            <Button size="regular" theme="primary">
              {props.button.label}
            </Button>
          </Link>
        </div>
      </div>
    </LargeTileContent>
  </Tile>
)

export default LargeTile
