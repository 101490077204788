import { useApolloClient } from "@apollo/client"
import { useLocation } from "@reach/router"
import ShopTiles, { formatStrapiTile } from "components/Strapi/ShopTiles"
import Loader from "components/UI/Loader"
import { Link, navigate } from "gatsby"
import Cookie from "js-cookie"
import { Button, useConfettis } from "nzk-react-components"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
// @ts-ignore
import { useShoppingCart } from "use-shopping-cart"
import useSentry from "../../../../hooks/useSentry"
import CHECKOUT_SESSION_COMPLETE from "../graphql/shop_checkoutSessionComplete.graphql"

const Wrapper = styled.div`
  color: #662d91;
  padding-top: 40px;
`

const Title = styled.div`
  font-size: 26px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  font-weight: bold;
`

const Content = styled.div`
  margin-bottom: 100px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  > * {
    margin-right: 20px;
  }
  > :last-child {
    margin-right: 0;
  }
  margin-bottom: 50px;
`

const Order = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`
const LineItems = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  > * {
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const LineItem = styled.div`
  display: flex;
  align-items: center;
  .product--image {
    width: 100px;
    height: 100px;
    background-color: #aeaeae;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    margin-right: 20px;
  }
  .product-info--name {
    font-size: 18px;
    font-weight: bold;
  }
  .product-info--description {
    font-size: 14px;
    margin: 8px 0;
  }
  .product-info--download {
    margin-top: 8px;
  }
  .product-info--download-link-notice {
    margin-top: 6px;
    font-style: italic;
  }
`

const LoaderWrapper = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 19px;
  margin-bottom: 20px;
`

const OrderComplete = ({ tiles }: { tiles: Strapi.ShopTile[] }) => {
  const client = useApolloClient()
  const { clearCart } = useShoppingCart()
  const location = useLocation()
  const [order, setOrder] = useState<any>()
  const [loading, setLoading] = useState(false)
  const { logError } = useSentry()
  const [error, setError] = useState<any>()
  const { ANIMATIONS } = useConfettis()

  const exposeOrderToAnalytics = order => {
    try {
      window.postMessage(
        {
          event: "ShopCheckoutSuccess",
          payload: {
            transactionId: order._id,
            total: order.lineItems.reduce((acc, item) => {
              return (
                acc +
                parseInt(item.price_data.unit_amount || 0, 10) *
                  (item.quantity || 1)
              )
            }, 0),
            currency: order.lineItems[0].price_data.currency,
            stripeCustomerId: order.stripeCustomerId,
            stripeChargeId: order.stripeChargeId,
            transactionProducts: order.lineItems.map(lineItem => ({
              sku: lineItem.sku,
              name: lineItem.price_data.product_data.name,
              price: lineItem.price_data.unit_amount,
              currency: lineItem.price_data.currency,
              quantity: lineItem.quantity,
            })),
          },
        },
        location.origin
      )
    } catch (err) {
      console.error("Failed to trigger ShopCheckoutSuccess.")
    }
  }

  const checkoutSessionComplete = async () => {
    const orderId = Cookie.get("nzk-order-checkout")
    if (!orderId) {
      navigate("/shop")
      return null
    }
    setLoading(true)
    try {
      const { data } = await client.mutate({
        mutation: CHECKOUT_SESSION_COMPLETE,
        variables: {
          orderId,
        },
      })
      setLoading(false)
      const { shop_checkoutSessionComplete: order } = data
      setOrder(order)
      exposeOrderToAnalytics(order)
      clearCart()
      Cookie.remove("nzk-order-checkout")
      try {
        ANIMATIONS.sideCanons({
          durationInMs: 4000,
          colors: ["#ff006e", "#8338ec"],
        })
      } catch (err) {
        console.error(err)
      }
    } catch (err) {
      setError(err)
      logError(err, {}, { orderId })
      setLoading(false)
    }
  }

  const lineItems = useMemo(() => {
    if (order) return order.lineItems
    return []
  }, [order])

  useEffect(() => {
    checkoutSessionComplete()
  }, [])

  if (error) {
    return (
      <Wrapper>
        <Content>
          <Title>Uh oh</Title>
          <Message>
            It looks like an error has occured. Try refreshing this page.
          </Message>
          <Message>
            <Button
              theme="primary"
              size="regular"
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.assign(window.location.pathname)
                }
              }}
            >
              Refresh
            </Button>
          </Message>
          <Message>
            If the problem persists, please contact&nbsp;
            <a href="mailto:support@nightzookeeper.com">
              support@nightzookeeper.com
            </a>
          </Message>
        </Content>
      </Wrapper>
    )
  }

  if (loading) {
    return (
      <Wrapper>
        <Title>Almost there!</Title>
        <LoaderWrapper>
          <Loader color="#662D91" placeholder="Processing Order..." />
        </LoaderWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Title>Checkout Success</Title>
      <Order>
        <LineItems>
          {lineItems.map((lineItem, i) => (
            <LineItem key={i}>
              <div className="product--image">
                <img
                  src={lineItem.price_data?.product_data?.images[0]}
                  alt={lineItem.price_data?.product_data?.name}
                />
              </div>
              <div className="product-info">
                <div className="product-info--name">
                  {lineItem.price_data?.product_data?.name}
                </div>
                <div className="product-info--description">
                  {lineItem.price_data?.product_data?.description}
                </div>
                <div className="product-info--quantity">
                  Quantity: {lineItem.quantity}
                </div>
                { ['NZK_SUBSCRIPTION_GIFT_FAMILY', 'NZK_SUBSCRIPTION_GIFT_SINGLE'].indexOf(lineItem.sku) >= 0 && <div>
                  We've sent you an email with all the details you need! Make sure to check your inbox and your spam folder.
                </div> }
                {lineItem.file && (
                  <div className="product-info--download">
                    <Button
                      size="small"
                      theme="primary"
                      onClick={() => window.open(lineItem.file, "_blank")}
                    >
                      Download
                    </Button>
                    <div className="product-info--download-link-notice">
                      Please note that this link will expire in 7 days.
                    </div>
                  </div>
                )}
              </div>
            </LineItem>
          ))}
        </LineItems>
      </Order>
      {tiles && tiles.length > 0 && (
        <ShopTiles tiles={tiles.map(formatStrapiTile)} />
      )}
      <Actions>
        <Link to="/shop">
          <Button theme="purple" size="regular">
            Back to Shop
          </Button>
        </Link>
      </Actions>
    </Wrapper>
  )
}

export default OrderComplete
